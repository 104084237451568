import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Uses`}</h1>
    <h2>{`Software`}</h2>
    <h3>{`Operating system`}</h3>
    <p><a parentName="p" {...{
        "href": "https://getfedora.org/en/workstation",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Fedora 32 Workstation`}</a>{` `}<a parentName="p" {...{
        "href": "https://www.gnome.org",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Gnome`}</a>{` edition.`}</p>
    <h3>{`App Store`}</h3>
    <a {...{
      "href": "https://flathub.org",
      "target": "blank"
    }}>{`
  `}<img parentName="a" {...{
        "width": "128px",
        "alt": "Flathub",
        "src": "https://flathub.org/assets/badges/flathub-badge-en.png"
      }}></img>
    </a>
    <h3>{`Editor`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://code.visualstudio.com",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Visual Studio Code`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.android.com/studio",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Android Studio`}</a></li>
    </ul>
    <h3>{`Desktop Apps`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.mozilla.org/en-US/firefox/new/",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Mozilla Firefox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.gnome.org/Apps/Photos",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Gnome Photos`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.gnome.org/Apps/Calendar",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Gnome Calendar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.gnome.org/Apps/Tweaks",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Gnome Tweaks`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://jangernert.github.io/FeedReader/",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`FeedReader`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.postman.com/",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Postman`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://peek.uploadedlobster.com/",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Peek`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://babluboy.github.io/bookworm/",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Bookworm`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.spotify.com",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Spotify`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.videolan.org/vlc/",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`VLC`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Alecaddd/sequeler",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Sequeler`}</a></li>
    </ul>
    <h4>{`Mozilla Firefox Extensions`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://addons.mozilla.org/en-US/firefox/addon/https-everywhere/",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`HTTPS Everywhere`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://getadblock.com/",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`AdBlock`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://addons.mozilla.org/et/firefox/addon/angular-augury/",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Augury`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://addons.mozilla.org/en-US/firefox/addon/react-devtools/",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`React Developer Tools`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://addons.mozilla.org/en-US/firefox/addon/grammarly-1/",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Grammarly`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://addons.mozilla.org/en-US/firefox/addon/gnome-shell-integration/",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`GNOME Shell integration`}</a></li>
    </ul>
    <h3>{`CLI tools`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://yarnpkg.com/",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Yarn`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://yarnpkg.com/package/gatsby-cli",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Gatsby-cli`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://create-react-app.dev/",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Create React App`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cli.angular.io/",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Angular CLI`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://dotnet.microsoft.com/download",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`.Net Core 3.1`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://expo.io/tools#cli",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Expo CLI`}</a></li>
    </ul>
    <h2>{`Hardware`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.amd.com/en/products/graphics/radeon-rx-580",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Radeon RX 580 Series`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ark.intel.com/content/www/us/en/ark/products/97144/intel-core-i5-7600k-processor-6m-cache-up-to-4-20-ghz.html",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Intel® Core™ i5-7600K CPU @ 3.80GHz × 4`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.amazon.com/Kingston-HyperX-2133MHz-Non-ECC-HX421C14FB/dp/B00TY6A56U",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Kingston 8GB 2133MHz DDR4 CL14 HyperX Fury DIMM (24GB total)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.samsung.com/us/computing/memory-storage/solid-state-drives/ssd-960-evo-m-2-250gb-mz-v6e250bw/",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Samsung SSD 960 EVO 250GB`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.fractal-design.com/products/cases/meshify/meshify-s2-dark-tempered-glass/black-tgd/",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Fractal Design Meshify`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      